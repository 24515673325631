.container {
    display: block;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-fluid {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.mc-db {
    display: block;
}

.mc-pr {
    position: relative;
}

.mc-mt--10 {
    margin-top: -10px;
}

.mc-mt-5 {
    margin-top: 5px;
}

.mc-mb-0 {
    margin-bottom: 0 !important;
}

.mc-mb-5 {
    margin-bottom: 5px;
}

.mc-mr-5 {
    margin-right: 5px;
}

.mc-ml-5 {
    margin-left: 5px;
}

.mc-ml-10 {
    margin-left: 10px;
}

.mc-mt-10 {
    margin-top: 10px;
}

.mc-mb-10 {
    margin-bottom: 10px;
}

.mc-mr-10 {
    margin-right: 10px;
}

.mc-mt-15 {
    margin-top: 15px;
}

.mc-mt-30 {
    margin-top: 30px;
}

.mc-mt-100 {
    margin-top: 100px;
}

.mc-mb-15 {
    margin-bottom: 15px;
}

.mc-mr-15 {
    margin-right: 15px;
}

.mc-ml-15 {
    margin-left: 15px;
}

.mc-mt-20 {
    margin-top: 20px;
}

.mc-mb-20 {
    margin-bottom: 20px;
}

.mc-mr-20 {
    margin-right: 20px;
}

.mc-ml-20 {
    margin-left: 20px;
}

.mc-mtb-5 {
    margin: 5px 0;
}

.mc-mtb-30 {
    margin: 30px 0;
}

.mc-mtb-100 {
    margin: 100px 0;
}

.width-fluid {
    width: 100% !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.mc-table-responsive {
    width: 100%;
    overflow-x: auto;
}

.mc-table-responsive.white-bg table th {
    background-color: #ffffff !important;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

table th, td {
    text-align: left;
    padding: 15px;
}

table th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

table thead th {
    vertical-align: bottom;
    border: 1px solid #dee2e6;
    color: #495057;
}

table td, .table-bordered th {
    border: 1px solid #dee2e6;
}

table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table thead > tr > th:not(:nth-child(2)),
table tbody > tr > td:not(:nth-child(2)) {
    text-align: center;
}

table tbody > tr > td:nth-child(2) {
    cursor: pointer;
}

table tbody .actions-icons .anticon {
    cursor: pointer;
}

table tbody > tr > td:nth-child(2) > a {
    text-decoration: none;
    color: #1d2124;
    display: block;
    width: 100%;
}

table .anticon {
    font-size: 25px;
}

table .anticon.active {
    color: #40A9FF;
}

.responsive-image {
    width: 100%;
}

.ant-modal {
    padding-top: 24px;
}

.tox-notification--warning {
    display: none !important;
}

.page-header {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.page-header a {
    color: rgba(0, 0, 0, 0.65);
}

.page-header a .anticon {
    font-size: 16px;
    margin-right: 5px;
}

.page-header .ant-divider-vertical {
    height: 20px;
    background: #cacaca;
}

.actions-icons > i {
    font-size: 22px;
    margin-right: 5px;
    margin-left: 5px;
}

.watermark-layer {
    background-image: url(https://s3.ap-south-1.amazonaws.com/copy-medcampus/wp-content/uploads/2019/02/22162701/mc-watermark.png);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border: 0;
    left: 0;
    right: 0;
    opacity: 0.07;
}

.full-width {
    width: 100%
}

.ww-bw {
    word-wrap: break-word;
}

.blue-text {
    color: blue;
}

.white-bg {
    background-color: #ffffff;
}


.br7 {
    border-radius: 7px !important;
}
