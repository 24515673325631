.userInfo {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 15px;
}

.userInfo .userInfo__userFullName {
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 0;
}

.userInfo .userInfo__userName {
    font-size: 12px;
    margin-top: 3px;
}
