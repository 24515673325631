.notes-table thead > tr > th:last-child,
.notes-table tbody > tr > td:last-child {
    min-width: 60px;
}

.notes-table thead > tr > th:nth-child(4),
.notes-table tbody > tr > td:nth-child(4) {
    min-width: 60px;
}

.notes-table thead > tr > th:nth-child(3),
.notes-table tbody > tr > td:nth-child(3) {
    min-width: 80px;
}
