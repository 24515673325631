/* TinyMCE toolbar hiding css */
.options-list-block.active .tox .tox-toolbar {
    display: none;
}

.options-list-block.active .tox .tox-edit-area {
    border: 0;
}

.reference-block.active .tox .tox-toolbar {
    display: none;
}

.reference-block.active .tox .tox-edit-area {
    border: 0;
}

/* Ckeditor toolbar hiding css */
.options-list-block.active .cke_top {
    display: none;
}

.reference-block.active .cke_top {
    display: none;
}

.media-item {
    width: 100%;
    max-width: 300px;
    margin: 10px 10px 10px 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.5);
}

.qbank-switch-bar {
    width: 60px;
}

.qbank-notes-switch {
    float: right;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
}

.qbank-assign-challenge-test-input {
    width: calc(100% - 70px) !important;
}

.qbank-assign-challenge-test-btn {
    width: 70px;
}
