.medvideo-list .mvideo-title {
    margin-bottom: 0;
}

.medvideo-list .ant-card-body,
.medvideo-grid .ant-card-body {
    padding: 0 !important;
}

.medvideo-list .ant-card-actions > li > span {
    display: block !important;
    padding: 0 20px !important;
}

.category-input {
    width: calc(100% - 70px) !important;
}