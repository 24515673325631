.slider-grid .ant-card-body {
    padding: 0;
}

.slider-image {
    display: grid;
    width: 100%;
    height: 200px;
    text-align: center;
    margin: 0 0 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 3px 10px 0 rgba(0, 0, 0, .1);
    cursor: pointer;
}

.slider-image > img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: auto;
}