.sa-card {
    position: relative;
    display: block;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.sa-card.fixed {
    max-width: 800px;
    margin: 20px auto;
}

.sa-card .info-icon {
    position: absolute;
    font-size: 20px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

@media screen and (max-width: 395px) {
    .ss-radio {
        width: 100%;
    }

    .ss-radio > .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
    }

    .ss-category-list {
        width: 100%;
        margin-top: 10px;
    }
}

@media screen and (min-width: 395px) {
    .ss-category-list {
        width: 160px;
        margin-left: 10px;
    }
}

.sa-card .bordered-div {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: -15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.bordered-div p {
    margin: 0;
    padding: 0;
}
