.change-password-section {
    max-width: 350px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 25px 20px 1px;
}

.change-password-section-icon {
    color: rgba(0, 0, 0, .25)
}

.change-password-section-button {
    width: 100%;
}
