.mc-video-section {
    display: block;
    width: 100%;
    max-width: 400px;
    height: auto;
    padding: 0;
    /*margin: auto;*/
}

.mc-video-section video {
    width: 100%;
    display: block;
    /*margin: auto;*/
    /*border-radius: 10px;*/
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

/*.mc-video-section p{*/
/*font-size: 20px;*/
/*font-weight: 700;*/
/*text-align: center;*/
/*margin: 10px 5px 0 5px;*/
/*}*/