.preview-question-modal img {
    width: 100% !important;
    height: auto !important;
}

.preview-question-modal audio {
    width: 100% !important;
    max-width: 400px !important;
}

.preview-question-modal video {
    width: 100% !important;
    max-width: 400px !important;
}

.preview-question-modal table {
    width: 100% !important;
    overflow-x: auto !important;
}
