.preview-note-modal img {
    width: 100% !important;
    height: auto !important;
}

.preview-note-modal audio {
    width: 100% !important;
    max-width: 400px !important;
}

.preview-note-modal video {
    width: 100% !important;
    max-width: 400px !important;
}

.preview-note-modal table {
    width: 100% !important;
    overflow-x: auto !important;
}
