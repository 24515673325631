.logo-white {
    width: 200px;
    margin-top: 50px;
}

.signup-form {
    text-align: left;
}

.signup-container {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.signup-form-forgot {
    float: right;
}

.signup-form-button {
    width: 100%;
}

.signup-form .phone-input-container {
    margin-bottom: 24px;
}

.signup-form .phone-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
}

.otp-input-wrapper > div {
    align-items: center;
    justify-content: center;
}
