.logo-white {
    width: 200px;
    margin-top: 50px;
}

.login-form {
    text-align: left;
}

.login-container {
    width: 100%;
    max-width: 350px;
    background-color: white;
    padding: 20px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}