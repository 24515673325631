.cba-card {
    position: relative;
    display: block;
    width: 100%;
    height: 350px;
    background-color: #ffffff;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.cba-card p {
    font-size: 11px;
    color: gray;
}

.cba-card .info-icon {
    position: absolute;
    font-size: 20px;
    top: 3px;
    right: 5px;
    cursor: pointer;
}
