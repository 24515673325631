.count-card {
    position: relative;
    display: block;
    width: 100%;
    min-height: 120px;
    border-radius: 7px;
    padding: 30px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.count-card .count-card__title {
    font-size: 18px;
    padding: 0;
    margin-bottom: 0;
}

.count-card .count-card__count {
    font-size: 25px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
}
