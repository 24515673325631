.qbank-table thead > tr > th:last-child,
.qbank-table tbody > tr > td:last-child {
    min-width: 70px;
}

.qbank-table thead > tr > th:nth-child(4),
.qbank-table tbody > tr > td:nth-child(4) {
    min-width: 150px;
}

.qbank-table thead > tr > th:nth-child(3),
.qbank-table tbody > tr > td:nth-child(3) {
    min-width: 120px;
}

.qbank-table .table-question-title img,
.qbank-table .table-question-title video {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
}

.status-td > i {
    font-size: 23px;
    margin: 0 5px;
}

.status-td > i.active {
    color: #0069d9;
}
