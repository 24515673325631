.stats-medal-card {
    position: relative;
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 7px;
    padding: 30px 20px;
    margin-bottom: 30px;
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.stats-card {
    position: relative;
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 7px;
    padding: 30px 20px;
    margin-bottom: 30px;
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.stats-card .info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    z-index: 1;
    cursor: pointer;
}

.stats-card > div {
    display: block;
    float: left;
}

.stats-card > div:first-child {
    width: 40%;
    height: 100%;
    text-align: center;
}

.stats-card > div:nth-child(2) {
    width: 60%;
    height: 100%;
    padding-left: 25px;
    border-left: 1px solid #e4e4e4;
}

.stats-card > div:nth-child(2) p:first-child {
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
}

.stats-card > div:nth-child(2) p:nth-child(2) {
    font-size: 25px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
}

.stats-card .ant-progress-circle .ant-progress-circle-trail {
    stroke: rgba(243, 243, 243, 0.20) !important;
}

.stats-card .ant-progress-circle .ant-progress-circle-path {
    stroke: #ffffff !important;
}

.dark-blue-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#408de5+0,75a6de+100 */
    background: #408de5; /* Old browsers */
    background: -moz-linear-gradient(top, #408de5 0%, #75a6de 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #408de5 0%, #75a6de 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #408de5 0%, #75a6de 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#408de5', endColorstr='#75a6de', GradientType=0); /* IE6-9 */
}

.pink-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d679b7+0,ec4fb8+100 */
    background: #d679b7; /* Old browsers */
    background: -moz-linear-gradient(top, #d679b7 0%, #ec4fb8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #d679b7 0%, #ec4fb8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #d679b7 0%, #ec4fb8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d679b7', endColorstr='#ec4fb8', GradientType=0); /* IE6-9 */
}

.lite-blue-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5dc7e4+0,4cbbd8+100 */
    background: #5dc7e4; /* Old browsers */
    background: -moz-linear-gradient(top, #5dc7e4 0%, #4cbbd8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #5dc7e4 0%, #4cbbd8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #5dc7e4 0%, #4cbbd8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5dc7e4', endColorstr='#4cbbd8', GradientType=0); /* IE6-9 */
}

.lite-gray-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3f4c6b+0,3f4c6b+100;Blue+Grey+Flat */
    background: #3f4c6b; /* Old browsers */
    background: -moz-linear-gradient(45deg,  #3f4c6b 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  #3f4c6b 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  #3f4c6b 0%,#3f4c6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f4c6b', endColorstr='#3f4c6b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.lite-green-bg {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2c539e+0,2c539e+100;Blue+3D+%232 */
    background: #2c539e; /* Old browsers */
    background: -moz-linear-gradient(45deg,  #2c539e 0%, #2c539e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  #2c539e 0%,#2c539e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  #2c539e 0%,#2c539e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c539e', endColorstr='#2c539e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

.wallet-card {
    position: relative;
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 7px;
    padding: 30px 20px;
    margin-bottom: 30px;
    color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.wallet-card > div {
    display: block;
    float: left;
}

.wallet-card > div:first-child {
    width: 50%;
    height: 100%;
    text-align: center;
}

.wallet-card > div:nth-child(2) {
    width: 50%;
    height: 100%;
    text-align: center;
    border-left: 1px solid #e4e4e4;
}

.wallet-card > div p:first-child {
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
}

.wallet-card > div p:nth-child(2) {
    font-size: 25px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
}
