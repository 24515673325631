.feedback-card {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 7px;
    padding: 30px 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.feedback-card .feedback-card__feedback-title {
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin-bottom: 20px;
}
