.wt-card {
    position: relative;
    display: block;
    width: 100%;
    height: 320px;
    overflow-y: auto;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ca65e7+0,8b52f1+100 */
    background: rgb(202, 101, 231); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(202, 101, 231, 1) 0%, rgba(139, 82, 241, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(202, 101, 231, 1) 0%, rgba(139, 82, 241, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(202, 101, 231, 1) 0%, rgba(139, 82, 241, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ca65e7', endColorstr='#8b52f1', GradientType=1); /* IE6-9 */
    border-radius: 7px;
    padding: 15px 20px 0;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.wt-card.fixed {
    height: 350px !important;
}

.wt-card h2 {
    color: #ffffff;
    font-weight: bold;
}

.wt-card .target-list {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.wt-card .target-list p {
    font-size: 13px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.28);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 0 0 7px;
}

.wt-card .target-list p.completed {
    color: rgba(255, 255, 255, 0.47);
    background-color: rgba(0, 0, 0, 0.28);
}

.wt-card .target-list p.completed::after {
    content: url("https://assets.medcampus.io/assets/images/check-mark%20%282%29.png");
    position: absolute;
    right: 15px;
}

.wt-card.completed p, .wt-card.completed h3 {
    color: #ffffff;
}
