.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.left-panel {
    min-width: 280px;
    max-width: 280px;
    background-color: #000518;
    /*padding: 0 20px;*/
    transition: all 0.3s;
}

.left-panel.active {
    margin-left: -280px;
}

@media (max-width: 768px) {
    .left-panel {
        margin-left: -280px !important;
    }

    .left-panel.active {
        margin-left: 0 !important;
    }
}

.left-panel .navbar-brand {
    text-align: center;
    padding-bottom: 20px;
    margin: 20px 0;
    border-bottom: 0.5px;
    border-bottom-color: gray;
    border-bottom-style: solid;
}

.navbar-list {
    padding: 0;
    list-style-type: none;
    color: #c8c9ce;
}

.navbar-list li {
    margin: 0;
}

.navbar-list li > a span {
    color: #c8c9ce;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    padding: 10px 20px;
}

.navbar-list li > a span:hover {
    background-color: #1d2124;
}

.navbar-list li > a.active span {
    background-color: #1d2124;
    color: #ffffff;
}

.navbar-list li > a:hover span {
    color: #ffffff;
}

.navbar-list li > a:focus {
    text-decoration: none;
}

.navbar-list li > a.active {
    text-decoration: none;
    color: #ffffff;
}

.navbar-list li > a i {
    margin-right: 20px;
}

.right-panel {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
}

.main-header {
    background-color: #ffffff;
    padding: 20px 15px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.secondary-header {
    background-color: #ffffff;
    margin-top: 7px;
}

.secondary-header-right {
    text-align: right;
}

.secondary-header p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 3px;
}

.header-icons-div {
    display: inline-block;
    margin-right: 15px;
}

.header-icons-div:last-child {
    margin-right: 0;
    margin-top: -5px;
}

.header-icons-div:last-child .file-upload-btn {
    position: absolute;
    top: 5px;
    bottom: 0;
}

.header-icons {
    margin-top: 7px;
}

.header-icons-div a {
    color: rgb(53, 52, 52);
}

.user-avatar {
    float: right;
    cursor: pointer;
}

.main-content-block {
    background-color: #ffffff;
    /*border-radius: 7px;*/
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
    .main-header {
        padding: 20px 7px;
    }

    .secondary-header {
        padding: 15px 7px;
    }

    .main-content-block {
        margin: 5px 7px !important;
        padding: 20px 10px;
    }

    .main-content-block-transparent {
        margin: 5px 7px !important;
    }
}

@media only screen and (min-width: 576px) {
    .secondary-header {
        padding: 15px;
    }

    .main-content-block {
        margin: 15px !important;
        padding: 20px 15px;
    }

    .main-content-block-transparent {
        margin: 15px !important;
    }
}

.preview-modal-content img {
    width: 100% !important;
    max-width: 400px !important;
    height: auto !important;
}

.preview-modal-content audio {
    width: 100%;
    max-width: 400px;
}

.preview-modal-content video {
    width: 100%;
    max-width: 400px;
}

.preview-modal-content table {
    width: 100% !important;
    height: auto !important;
}

.seo-section {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
    padding: 20px 20px 0;
    margin-top: 10px;
}

.mc-icon {
    font-size: 25px;
}

.mc-icon.active {
    color: #0069d9;
}

.mc-icon-large {
    font-size: 30px;
}

.mc-icon-small {
    font-size: 20px;
}

.cursor {
    cursor: pointer;
}
