.pc-section {
    display: block;
    width: 100%;
}

.pc-section.no-shadow {
    box-shadow: none !important;
}

@media only screen and (min-width: 750px) {
    .pc-section {
        display: block;
        width: 100%;
        max-width: 800px;
        padding: 20px;
        margin: 20px auto;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
    }
}
