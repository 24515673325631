.ssa-card {
    position: relative;
    display: block;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.10);
}

.ssa-card.fixed {
    max-width: 800px;
    margin: 20px auto;
}

.ssa-card .info-icon {
    position: absolute;
    font-size: 20px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
